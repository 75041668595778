<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'

//import Order from './GartenKultur-Order.vue'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const orders = ref([])
const newOrderDialog = ref(false)
const users = ref([])
const order = ref({
    company_name: "",
    company_uid: "",
    company_contact_type: "",
    company_street: "",
    company_street_nr: "",
    company_address2: "",
    company_postcode: "",
    company_city: "",
    company_country: "",
    company_employees: null,
    company_pensioners: null,
    company_pre_insurance: "",
    company_age_struktur: "",
    company_industry_noga: "",
    company_info_pension_commission: "",
    company_contact_pension_commission: "",
    company_website: "",
    company_email: "",
    company_phone: "",
    company_qualification: "",
    company_relationship: "",
    date: "",
    source: 1,
    type: 1,
    main_contact_name: "",
    main_contact_phone: "",
    main_contact_email: "",
    main_contact_role: "",
    notes: [],
    documents: [],
    offers: [],
    rep: null,
    status: 1
})
const sources = ref([
    {label: "Telefon", value: 1},
    {label: "E-Mail", value: 2},
    {label: "Web", value: 3},
    {label: "Broker", value: 4},
    {label: "Netzwerk", value: 5},
    {label: "Sonstiges", value: 6}
])
const qualification_types = ref([
    {label: "nicht kontaktiert", value: 1},
    {label: "kontaktiert Telefon", value: 2},
    {label: "kontaktiert E-Mail", value: 3},
    {label: "Gespräch", value: 4},
    {label: "Meeting (Remote)", value: 5},
    {label: "Offertenstellung", value: 6},
    {label: "Besprechung Offerte", value: 7},
    {label: "Auftrag", value: 8},
    {label: "Verloren", value: 9}
])
const types = ref([
    {label: "Interessent", value: 1},
    {label: "Kunde", value: 2},
    {label: "Lieferant", value: 3},
    {label: "Sonstiges", value: 4}
])
const contact_types = ref([
    {label: "Telefon", value: 1},
    {label: "E-Mail", value: 2}
])
const statuses = ref([
    {label: "Neu", value: 1},
    {label: "Kontaktaufnahme", value: 2},
    {label: "Offertenphase", value: 3},
    {label: "Unterzeichnungsphase", value: 4},
    {label: "Kunde", value: 5}
])
const active_workflow = ref("inbox")
const workflow_tabs = ref([])
const filter_orders = ref({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    status: {value: 1, matchMode: FilterMatchMode.EQUALS}
})

onMounted( async () => {
    get_orders()
    get_users()
})

const get_orders = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-orders')
    .then(response => {
        console.log(response.data)
        orders.value = response.data
        loader.value = false
    })
}

const get_users = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-users')
    .then(response => {
        console.log(response.data)
        users.value = response.data
        loader.value = false
    })
}

const new_order = () => {
    newOrderDialog.value = true
}

const save_new_order = async () => {
    loader.value = true
    let payload = order.value
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/prosperita/create-order", payload)
    .then(response => {
        console.log(response.data)
        newOrderDialog.value = false
        toast.add({severity:'success', summary: 'Kontakt erstellt', detail: 'Der Kontakt wurde erfolgreich erstellt.', life: 3000});
        loader.value = false
        get_orders()
    })
}

const delete_order = (e) => {
    confirm.require({
        message: 'Bist du sicher, dass du diesen Kontakt löschen möchtest?',
        header: 'Kontakt löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen',
        acceptClass: 'p-button-wanring',
        rejectLabel: 'Abbrechen',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gartenkultur/delete-order", e)
                .then(response => {
                    orders.value = response.data
                    toast.add({severity:'success', summary: 'Kontakt gelöscht', detail: 'Der Kontakt wurde erfolgreich gelöscht.', life: 3000});
                }).catch(error => {
                console.error("There was an error!", error.message);
            })
            loader.value = false
        },
        reject: () => {
            toast.add({severity:'info', summary: 'Kontakt nicht gelöscht', detail: 'Der Kontakt wurde nicht gelöscht.', life: 3000});
        }
    })
}

const open_workflow = (e) => {
    let workflow_id = e.data.id
    for (let key in orders.value){
        if (orders.value[key].id == workflow_id) {
            let found = workflow_tabs.value.some((el) => el.workflow_id === workflow_id);
            if (!found) {
                let name = ""
                if (orders.value[key].workflow_id > 25) {
                    name = orders.value[key].name.substring(0, 25) + "..."
                } else {
                    name = orders.value[key].name
                }
                workflow_tabs.value.push({
                    "workflow_id": orders.value[key].id,
                    "name": name
                })
                active_workflow.value = orders.value[key].id
            }
            else
            {
                active_workflow.value = e.data.id
            }
        }
    }
}

const close_tab = (workflow_id) => {
    for (let key in workflow_tabs.value){
        if (workflow_tabs.value[key]["workflow_id"] == workflow_id){
            workflow_tabs.value.splice(key, 1)
            console.log("workflow_tabs", workflow_tabs.value.length)
            if (workflow_tabs.value.length == 0) {
                active_workflow.value = "inbox"
            } else {
                active_workflow.value = workflow_tabs.value[workflow_tabs.value.length - 1]["workflow_id"]
            }
        }
    }
}

const validation_new_order_form = ref({
    main_infos_counter: 0,
    name_valid: true,
    name_message: 'Bitte geben Sie eine Beschreibung ein.',
    date_valid: true,
    date_message: 'Bitte geben Sie ein Datum ein.',
    source_valid: true,
    source_message: 'Bitte wählen Sie eine Quelle aus.',
    type_valid: true,
    type_message: 'Bitte wählen Sie einen Typ aus.',
})
const validate_order_form = () => {
    validation_new_order_form.value.main_infos_counter = 0
    if (!order.value.name) {
        validation_new_order_form.value.name_valid = false
        validation_new_order_form.value.main_infos_counter += 1
    }
    if (!order.value.date) {
        validation_new_order_form.value.date_valid = false
        validation_new_order_form.value.main_infos_counter += 1
    }
    if (!order.value.source) {
        validation_new_order_form.value.source_valid = false
        validation_new_order_form.value.main_infos_counter += 1
    }
    if (!order.value.type) {
        validation_new_order_form.value.type_valid = false
        validation_new_order_form.value.main_infos_counter += 1
    }
}

const filter_status = (status) => {
    filter_orders.value.status.value = status
    console.log(filter_orders.value)
}

watch ([order.value], (currentValue, oldValue) => {
    validate_order_form()
    console.log(validation_new_order_form.value)
})

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

</script>
<style>
.tab-primrary {
    background-color: #3751ff;
    color: #FFFFFF;
}
.tab-inactive {
    background-color: #0d2f3b;
    color: #FFFFFF;
}
.tab-hidden {
    display: none;
}
</style>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <Button rounded label="Alle Kontakte" icon="pi pi-inbox" v-tooltip.top="'Alle Kontakte (ausser Kunden) anzeigen'" @click="filter_status(null)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != 'inbox'}" class="mr-2" />
            <Button rounded label="Daten ergänzen" icon="pi pi-search-plus" v-tooltip.top="'Daten ergänzen für den ersten Kontakt'" @click="filter_status(1)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != 'inbox'}" class="mr-2" />
            <Button rounded label="Kontaktaufnahme" icon="pi pi-phone" v-tooltip.top="'Kontakte bereit für die erste Kontaktaufnahme'" @click="filter_status(2)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != 'inbox'}" class="mr-2" />
            <Button rounded label="Offertenphase" icon="pi pi-briefcase" v-tooltip.top="'Alle Kontakte in der Offertenphase'" @click="filter_status(3)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != 'inbox'}" class="mr-2" />
            <Button rounded label="Unterzeichnungsphase" icon="pi pi-briefcase" v-tooltip.top="'Alle Kontakte in der Unterzeichnungsphase'" @click="filter_status(4)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != 'inbox'}" class="mr-2" />
            <Button rounded v-for="(workflow_tab, key) in workflow_tabs" :key="key" @click="toggle_active_workflow(workflow_tab.workflow_id)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != workflow_tab.workflow_id}" class="mr-2"><i class="pi pi-file mr-3"/>{{workflow_tab.name}}<i class="pi pi-times-circle ml-3 text-red-100" @click="close_tab(workflow_tab.workflow_id)" /></Button>
        </template>
        <template #end>
            <Button label="Export" icon="pi pi-file-excel" v-tooltip.top="'Exportieren'" class="mr-2" />
        </template>
    </Toolbar>
    <div v-if="active_workflow == 'inbox'">
        <Toolbar class="mt-2">
            <template #start>
                <span class="p-input-icon-left mr-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_orders['global'].value" placeholder="Suche" class="w-full" />
                </span>
                <Button label="Neuer Kontakt" @click="new_order()" class="w-auto mr-2" icon="pi pi-plus" v-tooltip.right="'Neuer Kontakt erfassen'" />
            </template>
        </Toolbar>
        <DataTable class="mt-3" v-model:filters="filter_orders" :value="orders" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="date" header="Datum" sortable>
                <template #body="slotProps">
                    {{ format_swiss_date(slotProps.data.date) }}
                </template>
            </Column>
            <Column field="company_name" header="Firmenname">
                <template #body="slotProps">
                    <span class="cursor-pointer font-bold" @click="open_workflow(slotProps)">{{slotProps.data.company_name}}</span>
                </template>
            </Column>
            <Column field="type" header="Typ">
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.type == 1" label="Interessent" class="bg-pink-600 text-white" />
                    <Chip v-else-if="slotProps.data.type == 2" label="Kunde" class="bg-green-600 text-white" />
                    <Chip v-else-if="slotProps.data.type == 3" label="Lieferant" />
                    <Chip v-else-if="slotProps.data.type == 4" label="Sonstiges" />
                </template>
            </Column>
            <Column hidden field="source" header="Quelle">
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.source == 1" label="Telefon" class="bg-blue-600 text-white" />
                    <Chip v-else-if="slotProps.data.source == 2" label="E-Mail" class="bg-orange-600 text-white" />
                    <Chip v-else-if="slotProps.data.source == 3" label="Web" class="bg-yellow-600 text-white" />
                    <Chip v-else-if="slotProps.data.source == 4" label="Empfehlung" class="bg-purple-600 text-white" />
                    <Chip v-else-if="slotProps.data.source == 5" label="Sonstiges" class="bg-gray-600 text-white" />
                </template>
            </Column>
            <Column header="Stammdaten">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-primary" icon="pi pi-pencil" @click="newOrderDialog = true" badge="6" badgeSeverity="danger" />
                    <Button class="p-button-rounded p-button-primary" icon="pi pi-send" @click="open_workflow(slotProps)" />
                </template>
            </Column>
            <Column header="CRM">
                <template #body="slotProps">
                    <Avatar icon="pi pi-check" class="ml-2 bg-green-600 text-white" @click="open_workflow(slotProps)" shape="circle" />
                </template>
            </Column>
            <Column header="Dokumente">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-primary" icon="pi pi-paperclip" @click="open_url(slotProps)"  badge="6" badgeSeverity="info" />
                    <Button class="p-button-rounded p-button-text p-button-success" icon="pi pi-plus" @click="open_url(slotProps)" />
                </template>
            </Column>
            <Column header="Notizen">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-primary" icon="pi pi-file-edit" @click="open_url(slotProps)"  badge="13" badgeSeverity="info" />
                    <Button class="p-button-rounded p-button-text p-button-success" icon="pi pi-plus" @click="open_url(slotProps)" />
                </template>
            </Column>
            <Column header="Offerten">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-primary" icon="pi pi-file" @click="open_url(slotProps)"  badge="2" badgeSeverity="info" />
                    <Button class="p-button-rounded p-button-text p-button-success" icon="pi pi-plus" @click="open_url(slotProps)" />
                </template>
            </Column>
            <Column field="rep" header="Owner">
                <template #body="slotProps">
                    <Dropdown placeholder="Owner auswählen" :options="users" optionLabel="name" optionValue="id" v-model="slotProps.data.rep" class="w-full" />
                </template>
            </Column>
            <Column field="status" header="Status">
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.status == 1" label="Neu" class="bg-blue-600 text-white" />
                    <Chip v-else-if="slotProps.data.status == 2" label="Kontaktaufnahme" class="bg-orange-600 text-white" />
                    <Chip v-else-if="slotProps.data.status == 3" label="Offertenphase" class="bg-yellow-600 text-white" />
                    <Chip v-else-if="slotProps.data.status == 4" label="Unterzeichnungsphase" class="bg-purple-600 text-white" />
                    <Chip v-else-if="slotProps.data.status == 5" label="Kunde" class="bg-gray-600 text-white" />
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-danger" icon="pi pi-trash" @click="delete_order(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
    <div v-for="(workflow_tab, key) in workflow_tabs" :key="key" :hidden="active_workflow != workflow_tab.workflow_id">
        <!--<Purchase :workflow_id="workflow_tab.workflow_id" />-->
    </div>
    <Dialog v-model:visible="newOrderDialog" :style="{width: '1200px'}" header="Neuer Kontakt" :modal="true" class="p-fluid z-5">
        <Accordion :activeIndex="0" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">Hauptinfos</span>
                        <Badge v-if="validation_new_order_form.main_infos_counter > 0" :value="validation_new_order_form.main_infos_counter" class="ml-auto mr-2 bg-red-600" />
                        <Avatar v-else icon="pi pi-check" class="ml-auto mr-2 bg-green-600 text-white" shape="circle" />
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_name" type="text" v-model="order.company_name" />
                            <label for="company_name">Firmenname</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_uid" type="text" v-model="order.company_uid" />
                            <label for="company_uid">Firmen UID</label>
                        </span>
                    </div>
                    <div class="field col-2 mt-3">
                        <Button label="Zefix" class="w-full" icon="pi pi-search" />
                    </div>
                    <div class="field col-3 mt-3">
                        <span class="p-float-label">
                            <Dropdown id="rep" v-model="order.rep" :options="users" optionLabel="name" optionValue="id" />
                            <label for="rep">Vertreter</label>
                        </span>
                    </div>
                    <div class="field col-3 mt-3">
                        <span class="p-float-label">
                            <Calendar id="date" showIcon v-model="order.date" dateFormat="dd.mm.yy" />
                            <label for="date">Eingangsdatum</label>
                        </span>
                    </div>
                    <div class="field col-3 mt-3">
                        <span class="p-float-label">
                            <Dropdown id="source" v-model="order.source" :options="sources" optionLabel="label" optionValue="value" />
                            <label for="source">Quelle</label>
                        </span>
                    </div>
                    <div class="field col-3 mt-3">
                        <span class="p-float-label">
                            <Dropdown id="type" v-model="order.type" :options="types" optionLabel="label" optionValue="value" />
                            <label for="type">Typ</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="main_contact_name" type="text" v-model="order.main_contact_name" />
                            <label for="main_contact_name">Kontaktperson</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_pre_insurance" type="text" v-model="order.main_contact_role" />
                            <label for="company_pre_insurance">Funktion der Ansprechperson</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <Dropdown id="company_contact_type" v-model="order.company_contact_type" :options="contact_types" optionLabel="label" optionValue="value" />
                            <label for="company_contact_type">Kontaktmöglichkeit</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <InputText id="main_contact_phone" type="text" v-model="order.main_contact_phone" />
                            <label for="main_contact_phone">Kontaktperson Telefon</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <InputText id="main_contact_email" type="text" v-model="order.main_contact_email" />
                            <label for="main_contact_email">Kontaktperson E-Mail</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_pre_insurance" type="text" v-model="order.company_pre_insurance" />
                            <label for="company_pre_insurance">Vorversicherer (Firmenname)</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <Dropdown id="company_qualification" v-model="order.company_qualification" :options="qualification_types" optionLabel="label" optionValue="value" />
                            <label for="company_qualification">Qualifikation</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">Firmendaten</span>
                        <Badge value="3" class="ml-auto mr-2 bg-red-600" />
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_street" type="text" v-model="order.company_street" />
                            <label for="company_street">Strasse</label>
                        </span>
                    </div>
                    <div class="field col-2 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_street_nr" type="text" v-model="order.company_street_nr" />
                            <label for="company_street_nr">Strasse Nr.</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_address2" type="text" v-model="order.company_address2" />
                            <label for="company_address2">Adresszusatz</label>
                        </span>
                    </div>
                    <div class="field col-2 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_postcode" type="text" v-model="order.company_postcode" />
                            <label for="company_postcode">PLZ</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_city" type="text" v-model="order.company_city" />
                            <label for="company_city">Ort</label>
                        </span>
                    </div>
                    <div class="field col-3 mt-3">
                        <span class="p-float-label">
                            <InputNumber id="company_employees" showButtons v-model="order.company_employees" class="neuraxis_inputnumber" />
                            <label for="company_employees">Anz. Beschäftigter Pers.</label>
                        </span>
                    </div>
                    <div class="field col-3 mt-3">
                        <span class="p-float-label">
                            <InputNumber id="company_pensioners" showButtons v-model="order.company_pensioners" class="neuraxis_inputnumber" />
                            <label for="company_pensioners">Anzahl Rentner</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_website" type="text" v-model="order.company_website" />
                            <label for="company_website">Webseite</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_email" type="text" v-model="order.company_email" />
                            <label for="company_email">E-Mail</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_phone" type="text" v-model="order.company_phone" />
                            <label for="company_phone">Telefon-Nr.</label>
                        </span>
                    </div>
                    <div class="field col-4 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_industry_noga" type="text" v-model="order.company_industry_noga" />
                            <label for="company_industry_noga">Branche (NOGA Code)</label>
                        </span>
                    </div>
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_info_pension_commission" type="text" v-model="order.company_info_pension_commission" />
                            <label for="company_info_pension_commission">Infos zur Vorsorgekommission</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_age_struktur" type="text" v-model="order.company_age_struktur" />
                            <label for="company_age_struktur">Altersstruktur</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="company_relationship" type="text" v-model="order.company_relationship" />
                            <label for="company_relationship">Kontext / Beziehung zu Prosperita</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">Dokumente</span>
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <FileUpload mode="basic" customUpload url="" accept="image/*" :maxFileSize="10000000" @uploader="uploadTitleImage" :auto="true" chooseLabel="Dokumente hochladen" class="w-auto mr-2" />
                        </span>
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">Notizen</span>
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <Textarea id="notes" type="text" v-model="order.notes" />
                            <label for="notes">Notizen</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        </Accordion>
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <Message v-if="validation_new_order_form.main_infos_counter > 0" severity="error">Bitte alle Pflichtfelder ausfüllen</Message>
                <Button label="Kontakt erfassen" @click="save_new_order()" class="w-auto mr-2 mb-1" icon="pi pi-check" />
            </div>
        </div>
    </Dialog>
</template>